import React, { FC, memo } from 'react';

export type FlagProps = {
	className?: string;
	lang:
		| 'cs'
		| 'de'
		| 'en'
		| 'es'
		| 'fr'
		| 'it'
		| 'hu'
		| 'nl'
		| 'no'
		| 'pl'
		| 'br'
		| 'ru'
		| 'ro'
		| 'sk'
		| 'fi'
		| 'sv'
		| 'uk'
		| 'da';
};
export const Flag: FC<FlagProps> = ({ className, lang }: FlagProps) => {
	const Svg = require(`!!svg-react-loader!./assets/${lang}.svg`);
	return <Svg className={className} />;
};

export default memo(Flag);
