import React, { FC, memo, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import gsap from 'gsap';
import { Transition } from 'react-transition-group';
import { CollapseProps } from './';
import Label from '../Label';
import Icon from '../Icon';

const StyledToggler = styled.div`
	padding: 0;
	border: none;
	outline: none;
	cursor: pointer;
	z-index: 1;
`;

const StyledIcon = styled(Icon)<Pick<CollapseProps, 'collapse'>>`
	margin-right: 8px;
	transition: all 200ms ease-out;
	font-weight: normal;
	transform: ${({ collapse }) => (collapse ? 'rotate(90deg)' : 'rotate(0)')};
`;

const StyledLabel = styled(Label)`
	div {
		color: ${({ theme }) => theme?.colors.grey_shades_with_blue[500]};
	}
	&:hover * {
		color: ${({ theme }) => theme?.colors.grey_shades_with_blue[700]};
	}
`;

const StyledContent = styled.div<{ offset?: number; visible?: boolean }>`
	height: 0;
	overflow: hidden;
	padding: ${({ offset, visible }) => (visible ? offset : 0)}px 0;
`;

export const Collapse: FC<CollapseProps> = ({
	children,
	controlLabel,
	onClick,
	offset = 0,
	...props
}: CollapseProps) => {
	const [visible, setVisible] = useState(false);

	const handleOnClick = useCallback(() => {
		setVisible(!visible);
		onClick && onClick(!!visible);
	}, [onClick, visible]);

	const onEnter = (node) => {
		gsap.set(node, { height: node.scrollHeight, opacity: 1 });
		gsap.from(node, { height: 0, opacity: 0, duration: 0.3, ease: 'power1.out' });
	};

	const onExit = (node) => {
		gsap.fromTo(
			node,
			{ height: node.scrollHeight, opacity: 1 },
			{ height: 0, opacity: 0, duration: 0.3 },
		);
	};

	return (
		<>
			<StyledToggler
				onClick={handleOnClick}
				className={props.controlClassName}
				data-testid="ui-collapse"
			>
				<StyledLabel labelTypo="textSemibold300">
					<StyledIcon collapse={!!visible} name="arrow-right-16" />
					{controlLabel}
				</StyledLabel>
			</StyledToggler>
			<Transition in={visible} onEnter={onEnter} onExit={onExit} timeout={300}>
				<StyledContent className={props.className} offset={offset} visible={visible}>
					{typeof children === 'function' ? children(visible) : children}
				</StyledContent>
			</Transition>
		</>
	);
};

Collapse.displayName = 'Collapse';

export default memo(Collapse);
