const createTypo = (
	fontWeight,
	fontSize,
	lineHeight,
	letterSpacing = 'normal',
	textTransform = 'none',
) => `
	font-family: var(--font-inter, Inter, system-ui, sans-serif);
	font-style: normal;
	font-weight: ${fontWeight};
	font-size: ${fontSize}px;
	line-height: ${lineHeight}px;
	letter-spacing: ${letterSpacing};
	text-transform: ${textTransform};
`;

const typography = {
	textRegular100: createTypo('normal', 11, 14),
	textRegular200: createTypo('normal', 12, 16),
	textRegular250: createTypo('normal', 13, 20),
	textRegular300_22: createTypo('normal', 14, 22),
	textRegular300_24: createTypo('normal', 14, 24),
	textRegular300_26: createTypo('normal', 14, 26),
	textRegular300: createTypo('normal', 14, 20),
	textRegular400: createTypo('normal', 16, 24),
	textRegular500: createTypo('normal', 18, 26, '-0.18px'),
	textRegular600: createTypo('normal', 21, 28),
	textRegular700: createTypo(400, 24, 30, '-1%'),

	textMedium100: createTypo(500, 11, 14),
	textMedium200: createTypo(500, 12, 16),
	textMedium250: createTypo(500, 13, 20),
	textMedium300_22: createTypo(500, 14, 22),
	textMedium300_24: createTypo(500, 14, 24),
	textMedium300_26: createTypo(500, 14, 26),
	textMedium300: createTypo(500, 14, 20),
	textMedium400: createTypo(500, 16, 24),
	textMedium500: createTypo(500, 18, 26, '-0.18px'),
	textMedium550: createTypo(500, 20, 28, '-1%'),
	textMedium600: createTypo(500, 21, 28),
	textMedium700: createTypo(500, 24, 30),
	textMedium1100: createTypo(500, 48, 56, `-${0.03}em`),

	textSemibold100: createTypo(600, 11, 14),
	textSemibold200: createTypo(600, 12, 16),
	textSemibold250: createTypo(600, 13, 20, `-${0.005}em`),
	textSemibold300_22: createTypo(600, 14, 22, `-${0.005}em`),
	textSemibold300_26: createTypo(600, 14, 26, `-${0.005}em`),
	textSemibold300: createTypo(600, 14, 20, `-${0.005}em`),
	textSemibold400: createTypo(600, 16, 24, `-${0.01}em`),
	textSemibold500: createTypo(600, 18, 26, '-0.18px'),
	textSemibold600: createTypo(600, 21, 28, `-${0.01}em`),
	textSemibold700: createTypo(600, 24, 30, `-${0.01}em`),
	textSemibold800: createTypo(600, 28, 34, `-${0.02}em`),

	title100: createTypo('bold', 11, 14),
	title200: createTypo('bold', 12, 16, `-${0.01}em`),
	title300: createTypo('bold', 14, 20, `-${0.02}em`),
	title400: createTypo('bold', 16, 22, `-${0.02}em`),
	title500: createTypo('bold', 18, 24, `-${0.02}em`),
	title600: createTypo('bold', 21, 26, `-${0.02}em`),
	title700: createTypo('bold', 24, 30, `-${0.02}em`),
	title800: createTypo('bold', 28, 34, `-${0.02}em`),
	title900: createTypo('bold', 32, 38, `-${0.03}em`),
	title1000: createTypo('bold', 37, 40, `-${0.03}em`),
	title1100: createTypo('700', 44, 48, `-${0.03}em`),

	special100: createTypo(400, 11, 16, '0.24em', 'uppercase'),
};

export default typography;
