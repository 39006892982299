import gql from 'graphql-tag';

export default gql`
	mutation migrateToExperiment($experiment: String!, $variant: String!) {
		migrateToExperiment(experiment: $experiment, variant: $variant) {
			success
			message
		}
	}
`;
