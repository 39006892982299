import gql from 'graphql-tag';

export default gql`
	query user($input: StripeUpgradeInput!) {
		user {
			activeService {
				getStripeUpgradePrice(input: $input) {
					taxRate
					subAmount
					prorateDiscount
					nextBillingPrice
					nextBillingDate
					currencyCode
					amount
					couponDiscount
					errors
				}
			}
		}
	}
`;
