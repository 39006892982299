import React, { useCallback, useEffect, useRef } from 'react';
import { Button, CloseButton, Dialog, Menu } from 'ui';
import { Iframe } from './InAppPopup.styled';
import { useIntl } from 'react-intl';

const InAppPopup = ({ onClose, url, language, onLoad }) => {
	const intl = useIntl();
	const iframeRef = useRef(null);

	useEffect(() => {
		window.addEventListener(
			'message',
			async (message) => {
				if (message.source !== iframeRef.current?.contentWindow) {
					return;
				}

				const msg = JSON.parse(message?.data);

				switch (msg?.event) {
					case 'onLoad':
						return (iframeRef.current.style.height = `${msg?.data?.height}px`);
				}
			},
			false,
		);
	}, []);

	const handleClose = useCallback(
		(skipped: boolean) => () => {
			onClose(skipped);
		},
		[onClose],
	);

	return (
		<Dialog hideClose>
			<Dialog.Window width={788} padding={0}>
				<Iframe
					ref={iframeRef}
					onLoad={onLoad}
					allow="fullscreen"
					src={url.replace('{lang}', language)}
				/>
				<div
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						zIndex: 1000,
					}}
				>
					<Menu
						dark
						size="sm"
						offset={[0, 60]}
						minWidth={192}
						placement="left-end"
						items={[
							{
								id: 'close',
								onClick: handleClose(true),
								label: intl.formatMessage({ id: 'app.product-tour-try-next-time' }),
							},
							{
								id: 'skip',
								onClick: handleClose(false),
								label: intl.formatMessage({ id: 'app.product-tour-skip-completely' }),
							},
						]}
					>
						<CloseButton className="closeButton">
							<Button variant="icon" startIcon="close-medium-24" size="sm" />
						</CloseButton>
					</Menu>
				</div>
			</Dialog.Window>
		</Dialog>
	);
};

export default InAppPopup;
