import React, { PureComponent } from 'react';
import { matchPath } from 'react-router';
import withRouter from '~/hoc/withRouter';
import cookie from 'js-cookie';
import { compose } from 'redux';
import { revalidateTrackingToken, getTrackingToken, getActivePage } from '~/utils';
import { API } from '~/constants';
import { mapProps } from './';
import query from '~/cache/query';
import mutation from '~/cache/mutation';
import withBusinessModel01Handling from './withBusinessModel01Handling';
import { InAppContentContext } from '~/components/InAppContentProvider';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const withSendTracking = (Component) =>
	class SendTracking extends PureComponent {
		static contextType = InAppContentContext;
		static propTypes = {
			email: PropTypes.string,
			inAppContent: PropTypes.array,
			openStripeUpgradeModal: PropTypes.func,
			language: PropTypes.string,
			location: PropTypes.object,
			ownProps: PropTypes.object,
		};

		sendTracking = (id, name, event_version, data) => {
			const matchedInAppContent = this.props.inAppContent.find(({ eventTriggers }) =>
				eventTriggers.some((event) => event === name),
			);
			const slug = matchedInAppContent?.url
				? new URL(matchedInAppContent?.url).pathname.split('/').pop()
				: null;
			const workflow_id = data?.workflow_id || uuid();

			if (matchedInAppContent) {
				setTimeout(() => {
					switch (slug) {
						case 'sales':
							return this.props.openStripeUpgradeModal({
								salesVariant: 'iframe',
								iframeProps: {
									trigger: name,
									url: matchedInAppContent.url,
									language: this.props.language,
									plan: data?.plan,
									period: data?.period,
									saleSource: data?.saleSource,
									lockPrice: data?.lockPrice,
									workflow_id: workflow_id,
								},
							});
						default:
							this.context.setInAppContent(matchedInAppContent, true);
					}
				}, matchedInAppContent.timeout);
			}

			revalidateTrackingToken();
			const { token } = getTrackingToken();
			const session = cookie.get('session');
			const { path } = getActivePage(this.props.location.pathname);
			const uid = matchPath({ path, end: true }, this.props.location.pathname)?.params?.uid;

			const body = new FormData();
			body.append('event_id', id); // id, number
			body.append('event_name', name); // string
			body.append('version', 'v3'); // verze appky
			body.append('url', window.location.href); // string, aktuální url
			body.append('screen_resolution', `${window.innerWidth}x${window.innerHeight}`);
			body.append('user', this.props.email); // z API - login uživatele, pokud je
			body.append('session_id', token); // libovolný náhodný string, který bude zachován během jednoho přihlášení
			body.append('event_timestamp', Date.now());
			if (session) {
				body.append('web_session_id', session); // z cookies - session ID
			}
			if (uid) {
				body.append('survey_id', uid); // z API - UID dotazníku předávané v URL
			}
			if (event_version) {
				body.append('event_version', event_version); // string
			}
			if (slug === 'sales' || data?.workflow_id) {
				body.append('workflow_id', workflow_id);
			}

			const object = {};
			body.forEach(function (value, key) {
				object[key] = value;
			});

			if (window.env.SMARTLOOK === 'true') {
				smartlook('track', name, object);
			}

			return fetch(`${API}/tracking-ext`, {
				method: 'POST',
				body,
			});
		};

		render() {
			return (
				<>
					<Component {...this.props.ownProps} sendTracking={this.sendTracking} />
				</>
			);
		}
	};

export default compose(
	mapProps((ownProps) => ({ ownProps })),
	withRouter,
	mutation('closeInAppContent', {
		update: ({ cache, variables, getQuery }) => {
			const query = {
				query: getQuery('user/inAppContent'),
			};
			const { user } = cache.readQuery(query);
			cache.writeQuery({
				...query,
				data: {
					user: {
						...user,
						inAppContent: user.inAppContent.filter(({ id }) => variables.input.id !== id),
					},
				},
			});
		},
	}),
	query('account', {
		fragments: ['UserContact', 'Language'],
		mapProps: ({ user }) => ({ email: user?.email, language: user?.language }),
		options: () => ({
			variables: {},
		}),
	}),
	query('user/inAppContent', {
		mapProps: ({ user }) => ({ inAppContent: user?.inAppContent, userID: user?.userID }),
	}),
	withBusinessModel01Handling,
	withSendTracking,
);
