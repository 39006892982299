const IN_APP_MODAL = {
	EMAIL_INVITATIONS: 'survey-invitation-campaigns',
	SOCIAL_MEDIA: 'social-media',
	SUMMARY_STATS: 'summary-stats',
	INDIVIDUAL_RESPONSES: 'individual-responses',
	DASHBOARD_GRAPH_CUST: 'custom-dashboards',
	URL_PARAMETERS: 'url-parameters',
	SAFE_DATA: 'safe-data',
	QR_CODE: 'qr-code',
	GA: 'ga',
	LOGIN_PAGE: 'login-page',
	AUDIT_LOGS: 'audit-logs',
	DATA_OWNERSHIP: 'data-ownership',
	TEAM_ADMINISTRATION: 'team-administration',
	SURVEY_EMBEDING: 'survey-embeding',
	EMBEDING_WIDGET: 'embeding-pop-over-widget',
	EMBEDING_IFRAME: 'embeding-iframe',
	EMBEDING_POPUP_W: 'embeding-pop-up-window',
	EMBEDING_BUTTON: 'embeding-button',
	SURVEY_REPORTS: 'survey-reports',
	SURVEY_EXPORTS: 'survey-exports',
};

const IN_APP_TOOLTIP = {
	STAR_RATING: 'star-rating',
	NPS: 'nps',
	SINGLE_CHOICE: 'single-choice',
	SINGLE_CHOICE_OTHER: 'single-choice-other',
	IMAGE_CHOICE: 'image-choice',
	MULTIPLE_CHOICE: 'multiple-choice',
	MULTIPLE_CHOICE_OTHER: 'multiple-choice-other',
	RATING_SCALE: 'rating-scale',
	RANKING: 'ranking',
	SEMANTIC_DIFFERENTIAL: 'semantic-differential',
	MATRIX_SINGLE: 'matrix-single-choice',
	MATRIX_MULTI: 'matrix-multiple-choice',
	MATRIX_TEXT: 'matrix-text',
	OPEN_TEXT: 'open-ended-text',
	OPEN_NUMBER: 'open-ended-number',
	OPEN_DATE: 'open-ended-date',
	OPEN_MAIL: 'open-ended-email',
	OPEN_PLACE: 'open-ended-place',
	CUSTOM_TEXT: 'custom-text',
};

const UNSUPPORTED_L = ['sk', 'ro', 'hu', 'ru', 'uk', 'da'];

export { IN_APP_MODAL, IN_APP_TOOLTIP, UNSUPPORTED_L };
