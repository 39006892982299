import React, { useRef, useEffect, memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@survio/ui';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { openModal } from '~/actions';
import { TYPES } from '~/pages/Pricing/constants';
import { getActivePage, isProPlan } from '~/utils';
import { events } from '../constants';
import SaleSourceContext from '~/components/UpgradeModalStripe/SaleSourceContext';
import withRouter from '~/hoc/withRouter';
import mutation from '~/cache/mutation';

import styles from '../styles.less';
import { useApolloClient } from '@apollo/client';
import getStripeCheckoutLink from '~/cache/queries/getStripeCheckoutLink';
import { PAGES } from '~/constants';
import getStripeUpgradePrice from '~/cache/queries/getStripeUpgradePrice';
import { withInAppData } from '~/hoc';
import { StripePremiumServicePeriod } from '../../../../graphql';

const Iframe = ({
	close,
	history,
	inAppData,
	onLoad,
	openModal,
	sendTracking,
	setUpgradeModal,
	stripeUpgrade,
	trigger,
	url,
	workflow_id,
	...props
}) => {
	const iframe = useRef();
	const eventVersion = useRef();
	const preCheckoutVersion = useRef('app');
	const element = useRef();
	const _didUpgrade = useRef(false);
	const feature = new URL(url).searchParams.get('msg_id');
	const { setSaleSource } = useContext(SaleSourceContext);
	const client = useApolloClient();

	const setSlide = useCallback(
		(plan) => {
			close();
			setUpgradeModal({ isOpen: true, plan });
		},
		[close, setUpgradeModal],
	);

	const trackEvent = useCallback(
		(event) => {
			if (!event) {
				return;
			}

			if (Array.isArray(event)) {
				event.forEach((e) => sendTracking(e.id, e.name, eventVersion.current, { workflow_id }));
			} else {
				sendTracking(event.id, event.name, eventVersion.current, { workflow_id });
			}
		},
		[sendTracking],
	);

	useEffect(
		() => () => {
			if (!_didUpgrade.current && trigger) {
				const { close } = events[trigger] || {};
				trackEvent(close);
			}
		},
		[trackEvent, trigger],
	);

	useEffect(() => {
		const { open } = events[trigger] || {};
		const name = open?.[0]?.name?.replace('_open_', '_on-mount_');
		sendTracking(0, name, eventVersion.current, { workflow_id });
	}, [sendTracking, trigger, workflow_id]);

	useEffect(() => {
		window.addEventListener(
			'message',
			async (message) => {
				if (message.source !== iframe.current?.contentWindow) {
					return;
				}

				const msg = JSON.parse(message?.data);
				const { open, activate } = events[trigger] || {};

				switch (msg?.event) {
					case 'onLoad':
						iframe.current?.contentWindow.postMessage(JSON.stringify(inAppData), '*');
						eventVersion.current = msg?.data?.version;
						preCheckoutVersion.current = msg?.data?.preCheckoutVersion;
						element.current = msg?.data?.salesSource?.element;

						return trackEvent(open);
					case 'GO_TO_STRIPE':
						try {
							const backLink = new URLSearchParams(window.location.search).get('backLink');
							const saleSource = {
								section: 'app',
								element: activate?.[0].name,
								version: eventVersion.current,
								preCheckoutVersion: preCheckoutVersion.current,
								...props.saleSource,
							};

							const { data } = await client.query({
								query: getStripeCheckoutLink,
								variables: {
									input: {
										successUrl:
											getActivePage(location.pathname).path === PAGES.pricing.path
												? `${window.location.origin}${backLink || ''}`
												: window.location.href,
										cancelUrl: window.location.href,
										saleSource,
										plan: msg?.data?.plan,
										period: msg?.data?.period,
									},
								},
							});

							if (data.user?.getStripeCheckoutLink?.url) {
								window.location.assign(data.user.getStripeCheckoutLink.url);
							}
						} catch (error) {
							console.error(error);
						}
						break;
					case 'GET_PRICES':
						const fetchPrice = async (period) => {
							try {
								const { data } = await client.query({
									query: getStripeUpgradePrice,
									options: () => ({
										fetchPolicy: 'network-only',
									}),
									variables: {
										input: {
											period,
											plan: msg?.data?.plan,
											promoCode: msg?.data?.promoCode,
										},
									},
								});
								return data.user?.activeService?.getStripeUpgradePrice;
							} catch (error) {
								console.error(`Error fetching ${period} price:`, error);
							}
						};

						const [MONTHLY, YEARLY] = await Promise.all([
							(inAppData.period || msg?.data?.period) === StripePremiumServicePeriod.Monthly
								? fetchPrice(StripePremiumServicePeriod.Monthly)
								: new Promise((resolve) => resolve()),
							fetchPrice(StripePremiumServicePeriod.Yearly),
						]);

						iframe.current?.contentWindow.postMessage(
							JSON.stringify({ event: 'PRICES', data: { MONTHLY, YEARLY } }),
							'*',
						);
						break;
					case 'UPGRADE':
						try {
							const saleSource = {
								section: 'app',
								element: activate?.[0].name || element.current,
								version: eventVersion.current,
								preCheckoutVersion: preCheckoutVersion.current,
								...props.saleSource,
							};

							await stripeUpgrade({
								input: {
									saleSource,
									plan: msg?.data?.plan,
									period: msg?.data?.period,
									promoCode: msg?.data?.promoCode,
								},
							});

							window.location.reload();
						} catch (error) {
							console.error(error);
						}

						break;
					case 'active_service':
						const saleSource = {
							section: 'app',
							element: activate?.[0].name,
							version: eventVersion.current,
							preCheckoutVersion: preCheckoutVersion.current,
							...props.saleSource,
						};

						if (msg.data.redirect) {
							history.push({
								pathname: msg.data.redirect,
								state: { saleSource },
							});
							close();
							return;
						}

						_didUpgrade.current = true;

						setSaleSource(saleSource);

						if (!msg.data.mktPreCheckout) {
							if (inAppData.cleverbridge) {
								close();
								const UpgradeModal = (await import('~/components/UpgradeModal')).default;
								const pro = isProPlan(TYPES[msg?.data?.service]);
								openModal(
									{
										upgrade: <UpgradeModal />,
									},
									'upgrade',
									{
										saleSource,
										type: TYPES[msg?.data?.service],
										period: pro ? 'YEARLY' : 'MONTHLY',
										selectedPeriod: 'YEARLY',
									},
								);
							} else {
								setSlide(msg?.data?.service || 'upgradePRO');
							}
						}

						return trackEvent(activate);
				}
			},
			false,
		);
	}, [
		client,
		close,
		history,
		inAppData,
		openModal,
		props.saleSource,
		setSaleSource,
		setSlide,
		stripeUpgrade,
		trackEvent,
		trigger,
	]);

	return (
		<Dialog onClose={close}>
			<Dialog.Window padding={0} className={styles.dialog}>
				<iframe
					allow="fullscreen"
					ref={iframe}
					width="100%"
					height="100%"
					frameBorder="0"
					src={url.replace('{lang}', inAppData.language)}
					onLoad={onLoad}
				/>
			</Dialog.Window>
		</Dialog>
	);
};

Iframe.propTypes = {
	close: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	inAppData: PropTypes.object.isRequired,
	onLoad: PropTypes.func.isRequired,
	openModal: PropTypes.func.isRequired,
	sendTracking: PropTypes.func.isRequired,
	setUpgradeModal: PropTypes.func.isRequired,
	stripeUpgrade: PropTypes.func.isRequired,
	trigger: PropTypes.string,
	url: PropTypes.string.isRequired,
	saleSource: PropTypes.object,
	workflow_id: PropTypes.string,
};

export default compose(
	memo,
	connect(null, {
		openModal,
	}),
	withRouter,
	mutation('setUpgradeModal'),
	mutation('stripeUpgrade'),
	withInAppData,
)(Iframe);
