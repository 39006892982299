export const LANGUAGES = [
	'cs',
	'de',
	'en',
	'es',
	'fr',
	'it',
	'hu',
	'nl',
	'no',
	'pl',
	'br',
	'ru',
	'ro',
	'sk',
	'fi',
	'sv',
	'uk',
	'da',
];

export const LANGUAGES_MAP = {
	br: 'Português',
	cs: 'Čeština',
	de: 'Deutsch',
	es: 'Español',
	fr: 'Français',
	it: 'Italiano',
	nl: 'Nederlands',
	pl: 'Polski',
	no: 'Norsk',
	hu: 'Magyar',
	ru: 'Русский',
	sk: 'Slovenčina',
	sv: 'Svenska',
	ro: 'Română',
	en: 'English',
	fi: 'Suomi',
	uk: 'Українська',
	da: 'Dansk',
};

export const LANGUAGES_WITHOUT_TEMPLATES = ['da', 'uk'];
