export const LOGIN_URL_SUFFIX = {
	en: 'signin',
	cs: 'prihlaseni',
	sk: 'prihlasenie',
	es: 'entrar',
	ru: 'voiti',
	de: 'anmelden',
	nl: 'aanmelden',
	pl: 'logowanie',
	pt: 'entrar',
	br: 'entrar',
	it: 'registrazione',
	no: 'logg-inn',
	fr: 'connexion',
	sv: 'registrering',
	hu: 'belepes',
	ro: 'conectare',
	fi: 'sisäänkirjautuminen',
	uk: 'uviyty',
	da: 'logind',
};
