import { PLANS_23, PLANS_24, PLANS_NEW, PLANS_OLD } from '~/constants/plans';

const EXPERIMENTS = {
	SEGMENTATION: 'segmentation',
	AB_TRIAL: 'ab_trial',
	AB_PUBLISH: 'ab_publish',
	AB_TRIAL_CONVERSION: 'ab_trial_conversion',
	AB_BUSINESS_MODEL: 'ab_business_model',
	RC: 'RC',
};

const SEGMENTATION_VARIANT = {
	NEW: 'new',
	REGISTER: 'register',
	TEAM: 'team',
};

const RC_VARIANT = {
	'24': '24',
};

const EXPERIMENT_STATE = {
	INCLUDED: 'INCLUDED',
	SKIPPED: 'SKIPPED',
	EXCLUDED: 'EXCLUDED',
	FINISHED: 'FINISHED',
};

const AB_TRIAL_VARIANT = {
	VARIANT2: '01',
	VARIANT3: '03',
	VARIANT5: '05',
};

const AB_BM_VARIANT = {
	V1: '01',
};

const PRICING_EXPERIMENT = {
	OLD_BM: 'old_bm',
	AB_BUSINESS_MODEL_01: 'ab_business_model_01',
	TEST_PRICE_23_A: 'test_price_23_A',
	TEST_PRICE_24_A: 'test_price_24_A',
};

const LOWEST_PRO_PLAN_MAP = {
	[PRICING_EXPERIMENT.OLD_BM]: PLANS_OLD.STARTER,
	[PRICING_EXPERIMENT.AB_BUSINESS_MODEL_01]: PLANS_NEW.PRO,
	[PRICING_EXPERIMENT.TEST_PRICE_23_A]: PLANS_23.BUSINESS_1,
	[PRICING_EXPERIMENT.TEST_PRICE_24_A]: PLANS_24.BUSINESS_1,
};

export {
	EXPERIMENTS,
	EXPERIMENT_STATE,
	SEGMENTATION_VARIANT,
	AB_TRIAL_VARIANT,
	AB_BM_VARIANT,
	PRICING_EXPERIMENT,
	LOWEST_PRO_PLAN_MAP,
	RC_VARIANT,
};
